var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.add ? "添加计算公式" : "普通计算公式",
        "z-index": 2000,
        closable: true,
        size: "large",
        "overflow-auto": true,
        "custom-style": { height: "580px", maxHeight: "none" },
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        { staticClass: "formula-box" },
        [
          _c(
            "div",
            { staticClass: "tinymce-view" },
            [
              _c("div", { staticClass: "formula-label" }, [
                _vm._v(" 计算公式 "),
              ]),
              _vm.editorShow
                ? _c("Tinymce-vue", {
                    staticClass: "tinymce",
                    attrs: { value: _vm.content, setting: _vm.setting },
                    on: {
                      input: function (res) {
                        return (_vm.content = res)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "operation-view dont-copy" },
            [
              _c("div", { staticClass: "other-operation" }, [
                _c(
                  "div",
                  { staticClass: "comparison-operator" },
                  [
                    _c("div", { staticClass: "comparison-label" }, [
                      _vm._v("快速运算符"),
                    ]),
                    _vm._l(_vm.operatorList, function (item) {
                      return _c(
                        "a-button",
                        {
                          key: item.id,
                          staticClass: "comparison-btn",
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.handleComparison(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c("div", { staticClass: "add-container" }, [
                  _vm.showChar
                    ? _c(
                        "div",
                        { staticClass: "add-text add-view" },
                        [
                          _c("div", { staticClass: "add-label" }, [
                            _vm._v("请添加文本"),
                          ]),
                          _c("a-input", {
                            attrs: { placeholder: "请输入文本" },
                            model: {
                              value: _vm.text,
                              callback: function ($$v) {
                                _vm.text = $$v
                              },
                              expression: "text",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "add-btn" },
                            [
                              _c("a-button", {
                                staticClass: "addText",
                                attrs: { type: "iconBtn", icon: "add" },
                                on: { click: _vm.addText },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "add-number add-view" },
                    [
                      _c("div", { staticClass: "add-label" }, [
                        _vm._v("请添加数值"),
                      ]),
                      _c("CbNumber", {
                        attrs: {
                          "decimal-separator": "",
                          placeholder: "请输入数值",
                        },
                        model: {
                          value: _vm.numberText,
                          callback: function ($$v) {
                            _vm.numberText = $$v
                          },
                          expression: "numberText",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "add-btn" },
                        [
                          _c("a-button", {
                            staticClass: "addText",
                            attrs: { type: "iconBtn", icon: "add" },
                            on: { click: _vm.addNumber },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "indicator-bin" }, [
                _c("div", { staticClass: "indicator-title content-title" }, [
                  _vm._v("点击选择指标字段"),
                ]),
                _c(
                  "div",
                  { staticClass: "indicator-list" },
                  _vm._l(_vm.indicatorList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "indicator-outer" },
                      [
                        _c("div", { staticClass: "indicator-groupName" }, [
                          _vm._v(" " + _vm._s(item.resourceName) + ": "),
                        ]),
                        _c(
                          "div",
                          { staticClass: "indicator-item" },
                          _vm._l(item.indexItemList, function (indexitem) {
                            return _c(
                              "div",
                              {
                                key: indexitem.id,
                                staticClass: "indicator-text",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleIndicator(indexitem)
                                  },
                                },
                              },
                              [_vm._v(" [" + _vm._s(indexitem) + "] ")]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "a-button",
                {
                  staticClass: "addIndicator",
                  attrs: { type: "link", prefix: "piliangdaoru" },
                  on: { click: _vm.addIndicator },
                },
                [_vm._v(" 添加自定义指标 ")]
              ),
            ],
            1
          ),
          !_vm.add
            ? _c("div", { staticClass: "bottom-value" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "form-label" }, [
                      _vm._v("封顶值"),
                    ]),
                    _c("CbNumber", {
                      attrs: { placeholder: "请输入封顶值" },
                      model: {
                        value: _vm.maxLimit,
                        callback: function ($$v) {
                          _vm.maxLimit = $$v
                        },
                        expression: "maxLimit",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "form-label minimum" }, [
                      _vm._v("保底值"),
                    ]),
                    _c("CbNumber", {
                      attrs: { placeholder: "请输入保底值" },
                      model: {
                        value: _vm.minLimit,
                        callback: function ($$v) {
                          _vm.minLimit = $$v
                        },
                        expression: "minLimit",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("customIndicator", {
            ref: "customIndicator",
            on: {
              addSucceed: function ($event) {
                return _vm.getAll()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }