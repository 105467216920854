var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        visible: _vm.visible,
        size: "normal",
        title: "添加自定义指标",
        closable: true,
      },
      on: {
        ok: _vm.handleOk,
        cancel: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _vm.visible
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": { span: 4 },
                "wrapper-col": { span: 20 },
              },
            },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "指标名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { "max-length": 10 },
                    on: {
                      blur: function () {
                        _vm.$refs.name.onFieldBlur()
                      },
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "指标类型", prop: "dataType" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      on: { change: _vm.dataTypeChange },
                      model: {
                        value: _vm.form.dataType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dataType", $$v)
                        },
                        expression: "form.dataType",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "N" } }, [
                        _vm._v(" 数值型 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.dataType == "N"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "指标小数", prop: "precision" } },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.form.precision,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "precision", $$v)
                            },
                            expression: "form.precision",
                          },
                        },
                        [
                          _vm._l(_vm.precisionList, function (item, index) {
                            return [
                              _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.dataType == "C"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "最大长度", prop: "length" } },
                    [
                      _c("CbNumber", {
                        model: {
                          value: _vm.form.length,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "length", $$v)
                          },
                          expression: "form.length",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "累加方式" } },
                [
                  _c("DictionariesInput", {
                    attrs: { parameter: "dimension_type" },
                    model: {
                      value: _vm.form.dimension,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dimension", $$v)
                      },
                      expression: "form.dimension",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "footer-text" }, [
        _vm._v(
          "自定义的指标项需要在算薪模块使用excel表将数据导入系统或在手工输入"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }